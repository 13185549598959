.personalization-addtional-considerations {
    display: flex;
    justify-content: space-between;
}

.optimize-budget-other-choice {
    margin-right: 84px;
}

.other-considerations {
    min-width: 500px;
    margin-right: 50px;
}

.effort-other-considerations {
    width: 100%;
    max-width: 500px;
    margin-right: 50px;

}