.contact-blocks {
    display: flex;
    text-align: center;
    margin-bottom: 100px;
    justify-content: space-between;
    padding: 0 240px;
}

.connect {
    display: flex;
    justify-content: center;
}

