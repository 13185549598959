.contact-title{
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.contact-body {
    max-inline-size: 150px;
    margin-top: 24px;

    font-size: 14px;
    color: black;
    text-align: center;
}