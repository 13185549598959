.personalization-header {
  margin-bottom: 50px;
  font-size: 30px;

}
.next-button {
  margin-top: 50px;
  background-color: #b6997d;
  color: white;
  border-color: white;
  --bs-btn-hover-bg: #bb926b;
  --bs-btn-active-bg: #bb926b;
  --bs-btn-active-border-color:#bb926b;
  --bs-btn-hover-border-color: #bb926b;
}

.list-group-item.personalization-listgroup {
  background-color: white;
  color: black;
  border-color: #bb926b;
}

.list-group-item.personalization-listgroup.active {
  background-color: #b6997d;
  color: white;
  border-color: #bb926b;
}

.personalization-cards {
  display: flex;
}

.personalization-textbox {
  border-color: #bb926b;
}

.personalization-card {
  border-color: #e4cc9d;
  margin-right: 20px;
  color: black;
}

.personalization-card.active {
  background-color: #e4cc9d;
  color: black;
  border-color: #e4cc9d;
}

.personalization-card-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 24px;
}

.next1-button {
  margin-top: 50px;
  background-color: #cbb49a;
  color: white;
  border-color: white;
  --bs-btn-hover-bg: #b39f88;
  --bs-btn-active-bg: #b39f88;
  --bs-btn-active-border-color: #b39f88;
  --bs-btn-hover-border-color: #b39f88;

}