.personalization-save-button {
    background-color: #cbb49a;
    border-color: #cbb49a;
    --bs-btn-hover-bg: #b39f88;
    --bs-btn-active-bg: #b39f88;
    --bs-btn-active-border-color: #b39f88;
    --bs-btn-hover-border-color: #b39f88;
    color: white;
    --bs-btn-hover-color: white;
    --bs-btn-active-color: white;
    
}

.placeholder-black::placeholder {
    color: black !important;
  }

.wide-modal {
    max-width: 800px;
}