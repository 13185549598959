.a-line {
    display: flex;
    justify-content: space-between;
}

.marketplace-standard-hr {
    margin-top: 0rem; 
    margin-bottom: 0rem;
}

.marketplace-gold-hr {
    color: goldenrod;
    opacity: 90%;
    width: calc(100% + 2rem); 
    margin-left: -1rem;
    border-width:2px;
}


.detailedpg-bold {
    font-weight: bold;
}