.flower-background {
    flex: 1;
}

.flower-background img {
    height: 100vh;
    width: auto;
}

.pers-1 {
    width: 50%;
    margin-top: 76px;
    margin-left: 76px
}

.flower-background {
    flex: 1; 
}
  
  .flower-background img {
    height: 100vh; 
    width: auto; 
  }

  .pers-pg1-container {
    display: flex;
    height: 100vh; 
  }