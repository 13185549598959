.marketplace-buttons {
    display: flex;
    
}

.marketplace-dropdowns {
    margin-right: 12px;
    
}

.marketplace-menu-dropdowns {
    background-color: white;
    color: black;
    --bs-btn-border-color: white;
    --bs-btn-hover-color: black;
    --bs-btn-hover-bg: white;
    --bs-btn-hover-border-color: white;
    --bs-btn-active-color: black;
    --bs-btn-active-bg: white;
    --bs-btn-active-border-color: white;
}
