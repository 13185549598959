.services-title {
    font: var(--bs-body-font-family);
    font-family: 'new-spirit', 'Georgia', serif !important;
    color: rgb(70, 48, 31);
    font-size: 44px; 
    text-align: left; 
    max-inline-size: 600px; 
    min-inline-size: 600px; 
    margin-bottom: 30px; 
    font-weight: 502; 
}

.services-body {
    font: var(--bs-body-font-family);
    color: rgb(70, 48, 31);
    font-size: 20px; 
    text-align: left; 
    max-inline-size: 510px; 
    min-inline-size: 510px; 
    margin-bottom: 30px; 
}

