.step-by-step-title {
    font-size: 24px;
    margin-bottom: 30px;
}

.step-by-step-description {
    margin-bottom: 6%;
}

.holding-hands {
    margin-left: 20%;
}