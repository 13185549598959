.counter {
    display: flex;
}

.increment-decrement-button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transform: scale(0.8);
    font-size: 25px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #e4cc9d;
    border-color: #e4cc9d;
    --bs-btn-hover-bg: #e6c88d;
    --bs-btn-active-bg: #e6c88d;
    --bs-btn-active-border-color: #e6c88d;
    --bs-btn-hover-border-color: #e6c88d;
}

.price-count {
    font-size: 20px;
    font: var(--bs-body-font-family);
}