.connect-title {
    font-size: 22px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 32px;
}

.connect-body {
    font-size: 14px;
    text-align: left;
    color: black

}

.connect-description-container {
    max-inline-size: 490px;
    margin-right: 100px;
}