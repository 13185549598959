.one-review {
    display: flex;
}

.review-title {
    display: flex;
    justify-content: space-between;
}

.review-button {
    background-color: rgb(229, 229, 229);
    color: black;
    border-color: rgb(206, 204, 204);
    --bs-btn-active-color:black;
    --bs-btn-active-bg:rgb(229, 229, 229);
    --bs-btn-active-border-color:rgb(206, 204, 204);
    --bs-btn-hover-border-color: rgb(206, 204, 204);
    --bs-btn-hover-color: black;
    --bs-btn-hover-bg: rgb(229, 229, 229); 
    font-size: 16px;
    height: 40px;
}

.marketplace-card {
    border-color: transparent;
    height: 66px
}

.marketplace-profile {
    margin-right: 10px;
}

.comment-text {
    margin-bottom: 0px;
}

.detailedpage-titles {
    font: var(--bs-body-font-family);
    font-size: 28px;
    font-weight: 600;
}