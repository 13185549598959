.rationale-block {
    max-width: 450px;
    --bs-card-border-color: #e4cc9d;
}

.rationale-title {
    font: var(--bs-body-font-family);
    font-weight: 600;
    font-size: 20px;
    
} 

.personalization-card {
    border-color: #e4cc9d;
}