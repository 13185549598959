.steps-title{
    display: flex;
    justify-content: space-between;
}

.steps-accordian .accordion-button {
    background-color:  #f4e6bd;
    color: black;
}

.steps-checkbox .form-check-input:checked {
    background-color: #f4e6bd;
    border-color: #f4e6bd;
  }
  
  .steps-checkbox .form-check-input:focus {
    box-shadow: none;
  }
  
  .steps-checkbox .form-check-label {
    color: black; 
  }

  .steps-checkbox .form-check-input:not(:checked) {
    background-color: transparent; 
    border-color: #cbb49a; 
  }

