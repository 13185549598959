.detailed-page {
    display: flex;
  }
  
  .left-side {
    flex: 1;
  }
  
  .right-side {
    flex: 1;
  }
  
  .vertical-line {
    width: 1px;
    background-color: black;
    margin: 20px 10px;
    margin-top: 3.23%; 
  }