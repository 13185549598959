.sidebar-button0 {
    background-color: transparent;
    border: none;
    color: black;
}

.sidebar-button1 {
    background-color: transparent;
    border: none;
    color: black;
}

.sidebar-button2 {
    background-color: transparent;
    border: none;
    color: black;
}

.sidebar-button3 {
    background-color: transparent;
    border: none;
    color: black;
}

.sidebar-button4 {
    background-color: transparent;
    border: none;
    color: black;
}

.sidebar-header {
    border-color: black;
}