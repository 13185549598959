.reviews {
    display: flex;
}



  .card-link {
    text-decoration: none;
  }

  .card-link:hover {
    text-decoration: none;
  }

  .card-link:hover .card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .top-10 {
    padding: 10px;
    font-family: var(--bs-body-font-family);
    font-size: 28px;
    font-weight: 600;
  }

  .sponsored-results {
    font-family: var(--bs-body-font-family);
    font-size: 20px;
    font-weight: 600;

  }
 
