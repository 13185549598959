.orange-container {
    background-color: rgb(251, 240, 217);
    margin-left: 1%;
    margin-right: 1%;
    border-radius: 50px;
   
}

.review-cards  {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    padding-left: 200px;
    padding-right: 200px;
    font-family: var(--bs-body-font-family);
}

.homepage-text-overlay {
    font-family: 'new-spirit', 'Georgia', serif !important;
    font-size: 44px; 
    padding-top: 50px;
}

.homepage-text-review {
    font-size: 30px; 
    padding-top: 50px;
    align-items: left;
    font-family: var(--bs-body-font-family);
}