.marketplace-reviews {
    display: block;
    margin-top: 10px;
    max-width: 150px;
}

.text-center {
    border-color: transparent;
}

.filter-header {
    background-color: transparent;
    text-align: left;
}

.price-selects {
    display: flex;
}

.small-icon svg{
    font-size: 16px;
}

.price-button {
    padding: 5px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 0;
    background-color: transparent;
    color: black;
    border-color: grey;
    --bs-btn-hover-color: black;
    --bs-btn-hover-bg:white;
    --bs-btn-hover-border-color: grey;
    --bs-btn-active-color: black;
    --bs-btn-active-bg: white;
    --bs-btn-active-border-color: grey;
}


.price-button1 {
    padding: 5px;
    padding-left: 15px;
    padding-right: 12px;
    border-radius: 0;
    background-color: transparent;
    color: black;
    border-color: grey;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    --bs-btn-hover-color: black;
    --bs-btn-hover-bg:white;
    --bs-btn-hover-border-color: grey;
    --bs-btn-active-color: black;
    --bs-btn-active-bg: white;
    --bs-btn-active-border-color: grey;
}

.price-button4 {
    padding: 5px;
    padding-left: 12px;
    padding-right: 15px;
    border-radius: 0;
    background-color: transparent;
    color: black;
    border-color: grey;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    --bs-btn-hover-bg:white;
    --bs-btn-hover-border-color: grey;
    --bs-btn-active-color: black;
    --bs-btn-active-bg: white;
    --bs-btn-active-border-color: grey;
    --bs-btn-hover-color: black;

}



.review-button1 {
    padding: 5px;
    border-radius: 0;
    background-color: transparent;
    color: black;
    border-color: grey;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    --bs-btn-hover-color: black;
    --bs-btn-hover-bg:white;
    --bs-btn-hover-border-color: grey;
    --bs-btn-active-color: black;
    --bs-btn-active-bg: white;
    --bs-btn-active-border-color: grey;
    padding-left: 15px;
    padding-right: 15px;
}

.review-button4 {
    padding: 5px;
    border-radius: 0;
    background-color: transparent;
    color: black;
    border-color: grey;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    --bs-btn-hover-bg:white;
    --bs-btn-hover-border-color: grey;
    --bs-btn-active-color: black;
    --bs-btn-active-bg: white;
    --bs-btn-active-border-color: grey;
    --bs-btn-hover-color: black;
    padding-left: 15px;
    padding-right: 15px;

}

.price-button2 {
    padding: 5px;
    border-radius: 0;
    background-color: transparent;
    color: black;
    border-color: grey;
    padding-left: 4px;
    padding-right: 4px;
    --bs-btn-hover-color: black;
    --bs-btn-hover-bg:white;
    --bs-btn-hover-border-color: grey;
    --bs-btn-active-color: black;
    --bs-btn-active-bg: white;
    --bs-btn-active-border-color: grey;
    padding-left: 15px;
    padding-right: 15px;
}

.dollar-icon {
    padding: 0;
}

.review-title {
    margin-top: 10px;
}