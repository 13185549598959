.right-side {
    margin-top: 5%;
    margin-left: 1%;
}

.rightside-words {
    font: var(--bs-body-font-family);
    font-size: 22px;
    font-weight: 500;
}

.rightside-button {
    width: 60%;
    background-color: #cbb49a;
    color: white;
    border-color: white;
    --bs-btn-hover-bg: #b39f88;
    --bs-btn-active-bg: #b39f88;
    --bs-btn-active-border-color:#b39f88;
    --bs-btn-hover-border-color: #b39f88;
}

.right-side {
    padding: 20px;
  }
  
  .rightside-words {
    margin: 10px 0;
  }
  
  .rightside-button {
    margin-bottom: 20px;
  }
  
 