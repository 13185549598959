.pers-1 {
    width:50%;
    margin-top: 76px;
    margin-left: 76px;
    margin-right: 76px;
}

.pers-pg1-container {
    display: flex;
}

.flower-background {
    opacity: 90%;
}


