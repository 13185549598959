.message-us {
    min-width: 500px;
}

.message.form-control, .email.form-control {
    border-color: black;
    border-radius: 24px;
}

.message-us .send-button {
    border-radius: 18px;
    margin-top: 12px;
}

.send-button-container {
    text-align: left;
}

.email-title-container {
    text-align: left;
    font-size: 14px;
    color:black
}

.message-title-container {
    text-align: left;
    font-size: 14px;
    color: black;
}