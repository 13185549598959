.marketplace-searchbar {
    display: flex;
    max-width: 780px;
    margin-left: 250px;
    margin-top: 30px
}

.marketplace-buttons {
    margin-left: 250px
}

.funeral-homes {
    max-width: 800px;
}

.funeralhomes-and-banner {
    display: flex;
    align-items: right;
}

.searchbar-button {
  background-color: #cbb49a;
  color: white;
  border-color: white;
  --bs-btn-hover-bg: #b39f88;
  --bs-btn-active-bg: #b39f88;
  --bs-btn-active-border-color:#b39f88;
  --bs-btn-hover-border-color: #b39f88;
}

hr {
    margin-bottom: 0;
  }

.mr-sm-1 {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.mr-sm-2 {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.marketplace-searchbar .form-control:focus {
    box-shadow: none;
    outline: none;
    border-color: black;
  }