.nav-links .nav-link {
    font-size: 18px;
    font-weight: 500;
}



.header-navbar a {
    text-decoration: none;
    color: unset;
}


  .header-navbar .navbar {
    background-color: #fffaee;
  }

  .header-navbar .navbar-brand {
    margin-left: 8%; 
  }


  .auth-buttons {
    margin-right: 8%;

  }

  .button2 {
    margin-left: 5px;
    --bs-btn-color: #fff;
    --bs-btn-bg: black;
    --bs-btn-border-color: black;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: black;
    --bs-btn-hover-border-color: black;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: black;
    --bs-btn-active-border-color: black;
  }

  .button1 {
    margin-left: 8px;
    --bs-btn-color: #fff;
    --bs-btn-bg: black;
    --bs-btn-border-color: black;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: black;
    --bs-btn-hover-border-color: black; 
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: black;
    --bs-btn-active-border-color: black;
  }




