.optimize-effort {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.save-my-plan {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}