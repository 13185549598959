.icons {
    display: flex;
}

.website-hours {
    margin-left: 5%;
}

.overview {
    justify-content: space-between;
}

.highlight {
    justify-content: space-between;
}

.market-detailed-about {
    font-size: 20px;
    margin-top: 50px;
}

.marketplace-line {
    color: black;
}

.detailedpage-firstline {
    display: flex;
}

.star-number {
    margin-right: 3%;
}

.google-reviews {
    margin-left: 1%;
    color: rgb(1, 73, 199);
}

.five {
    margin-right: 1%;
}

.forestlawn-titles {
    font: var(--bs-body-font-family);
    font-size: 40px;
    font-weight: 500;
}

.forest-lawn-line {
    color: black;
    opacity: 90%;
    border-width: 1px;
    width: calc(100% + 10px); 
}

.first-forest-lawn-line {
    color: black;
    opacity: 90%;
    border-width:1px;
    margin-top: 5%;
    width: calc(100% + 28%); 

}