.more-considerations {
    width: 100%;
    max-width: 500px;
}

.budget-price-and-rationale {
    display: flex;
}

.optimize-budget-title {
    font: var(--bs-body-font-family);
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 40px;
}

.price-and-rationale {
    display: flex;
}

