.EssentialServices {
  }
  
.services-header {
    color: rgb(70, 48, 31);
    color: black;
    font-size: 44px;
    text-align: center;
    align-items: center;
    font-weight: 502;
    font-family: 'new-spirit', 'Georgia', serif !important;
    display: flex;
    justify-content: center;
    height: 200px;
    margin-bottom: 0px;

 
}

.service1-container {
  display: flex;
  align-items: center;
  margin-bottom: 2%;
}



.service2-container {
  display: flex;
  align-items: center;
  margin-bottom: 2%;
}

.service3-container {
  display: flex;
  align-items: center;
  margin-bottom: 5%;
}

.essential-services-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.essential-services{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.desert-placeholder {
  margin-left: 10px;

}

.desert2-placeholder {
  margin-right: 80px;
}

.homepage-border {
  width: 100%;
  position: relative;

}

.homepage-top-image {
  width: 300px;
  margin-left: 190px;
}
.inner-div {
  display: flex;
  justify-content: center;
  align-items: center;
}