.personalization-price-title {
    display: flex;
    justify-content: center;
}

.total-price {
    margin-right: 50px;
}

.personalization-name-and-price {
    display: flex;
    justify-content: space-between;
}

.optimize-budget-first-choice {
    margin-right: 50px
}

.total-price {
    width: 100%;
    max-width: 500px;
}

