.Home {
  text-align: center;
}

.logo.navbar-brand {
  font-size: 32px;
  margin-left: 16px;
  font-weight: bold;
}

.nav-links .nav-link {
  font-size: 14px;
  
}

.nav-bar {
  display: flex;
  justify-content: space-between;
}

.banner-text{
  position: absolute;
  font-family: 'new-spirit', 'Georgia', serif !important;
  top: 30%;
  left: 8%;
  color: black; 
  font-size: 54px; 
  font-weight: 502; 
  background-color: transparent;
  max-width: 540px;
  text-align: left;
}

.homepage-button.btn {
  --bs-btn-border-radius: 40px;
  position: absolute;
  font-family: var(--bs-body-font-family);
  top: 76%;
  left: 8%;
  color: white; 
  font-size: 20px;
  padding: 10px 20px;
  --bs-btn-bg: black;
    --bs-btn-border-color: black;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: black;
    --bs-btn-hover-border-color: black;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: black;
    --bs-btn-active-border-color: black;
}

.Home {
  width: 100%;
  margin: 0;
  padding: 0;
}

.home-banner img {
  width: 100%;
  height: auto;
  margin-bottom: 0px;
  margin-inline-end: 0;
  margin-block-end: 0;
}

.Home a {
  text-decoration: none;
  color: unset;
}

